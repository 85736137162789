<template>
  <div class="header">
        <div class="hearder_list">
          <div class="container">
            <div class="hearder_logo">
              <img src="@/assets/image/bscm/logo.png" class="wow fadeInDown"  @click="goHome('/index')" alt=""/>
              <div class="hearder_right">
                  <!-- <span class="cursor active" v-if="!$store.state.accounts" 
                  @click="toastTextFun">{{$t("menu.nav05")}}</span>
                  <div v-else>
                    <span class="color999">{{$utils.formatName($store.state.accounts,4)}}</span>
                    <span> | <span class="colorfff cursor">DASHBOARD</span> 
                    </span>
                  </div> -->
              </div>

            </div>
          </div>
            
            <!-- 中英翻译，暂时屏蔽 -->
        </div>
        <div class="m_header">
          <div class="mobile_logo d_m_block"   @click="$router.push('/index')">
            <img src="@/assets/image/bscm/logo.png" alt="">
          </div>
          <div class="m_menu_right">
            <div class="" v-if="isMobile">

              <div class="icon_img">
                <label for="" class="mr50">
                  <a :href="$config.twitterUrl" target="_discord">
                    <img src="@/assets/image/bscm/Twitte.png" alt="">
                  </a>
                </label>
                <label for="" class="mr50">
                  <a :href="$config.tgUrl" target="_twitter">
                    <img src="@/assets/image/bscm/Telegram.png" alt="">
                  </a>
                </label>
              </div>

              <!-- <span class="cursor active" v-if="!$store.state.accounts" 
              @click="toastTextFun">{{$t("menu.nav05")}}</span>
              <div v-else>
                <span class="">{{$utils.formatName($store.state.accounts,4)}}</span>
              </div> -->
                
            </div>
            <span class="d_m_block cpr iconfont icon-gengduo" @click="show = !show"></span>
          </div>
          
        </div>
        <!-- font_amatic  -->
        <ul class="container menu_wrap d_m_none" v-if="show">
            <li v-for="(item,index) in menuList" :key="index">
               <a :href="'#'+item.path" 
               @click="handleClick(index,item.path)" 
               :class="{'isactive': $store.state.isActive === index}">
               {{item.name}}
              </a>
            </li>
            <li class="icon_img" v-if="!isMobile">
              <label for="" class="mr50">
                <a :href="$config.twitterUrl" target="_discord">
                  <img src="@/assets/image/bscm/Twitte.png" alt="">
                </a>
              </label>
              <label for="">
                <a :href="$config.tgUrl" target="_twitter">
                  <img src="@/assets/image/bscm/Telegram.png" alt="">
                </a>
              </label>
            </li>

            <li v-if="isMobile">
              <a href="javascript:0" v-if="$store.state.accounts" @click="goHome('/mine')">
                <span class="cursor" :class="{'active': $route.path == '/Orders'}">DASHBOARD</span> 
              </a>
            </li>
        </ul>
        
    <div class="new-popup" v-if="isMobile && show" @click="show = false"></div>
    </div>
</template>

<script>
export default {
  props:{
    menuList:{
      type:Array,
      default:[]
    },
    isActive:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
     isMobile:false,
     show:false,
     lang:window.localStorage.getItem("lang")
    };
  },
  components: {
   
  },
  created(){
    this.isMobile = this.$utils.isMobile();
  },
  mounted() {
    var options={
        //默认为true
        live:false
    }
    new this.$WOW.WOW(options).init();
      var that = this
      that.handleMobile();
      window.addEventListener('resize', () => {
        that.handleMobile();
      })
  },
  methods: {
    goHome(path){
      this.$emit('goHome',path)
    },
    translateEn (){
        this.$emit("translateEn", "en");
    },
    translateChinese() {
          this.$emit("translateChinese", "chinese");
    },
    handleMobile(){
      this.isMobile = window.matchMedia("(max-width: 768px)").matches;
      this.show = this.isMobile ? false : true
    },
    
    handleClick(index,path){
      if(this.isMobile){
        this.show = false
      }
      this.$emit('handleClick',index,path)
    },
    toastTextFun(){
      this.$emit('toastTextFun')
    },
  },
};
</script>

<style lang="scss" scoped>
.icon_img{
  display: flex;
  align-items: center;
  label{
    img{
      width: 24px;
    }
  }
  .mr50{
    margin-right: 50px;
  }
}
.hearder_right{
    position: absolute;
    right: 0px;
    top: 40px;
    .color999{
      color: #999;
    }
    .colorfff{
      color: #fff;
    }
    
}
.active {
    color: #F8C10A !important;
}
.isactive{
    color: #F8C10A !important;
}
.navTextColor{
    color: #F8C10A;
}
.header{
  padding-top: 20px;
    width: 100%;
    // height: 180px;
    z-index: 1;
    position: absolute;
    .hearder_list{
        height: 109px;
        position: relative;
        .hearder_translate{
            position: absolute;
            left: 0px;
            top: 40px;
            span{
                cursor: pointer;
            }
        }
    }
    .hearder_logo{
        cursor: pointer;
        text-align: center;
        position: relative;
        img{
            // width:262px;
            height:81px
        }
        .hearder_right{
          position: absolute;
          right: 0;
        }
    }
    ul{
        display: flex;
        align-items: center;
        // justify-content: space-around;
        justify-content: space-between;
        margin-top: 18px;
        padding-bottom: 20px;
        position: relative;
        flex-wrap: nowrap;
        &::before{
          contain: none;
          display: none;
        }
        &:after{
                content: '';
                display: block;
                width: 100%;
                height: 12px;
                background: url('../assets/image/bscm/line.png') no-repeat left top/100%;
                position: absolute;
                left: 0;bottom: 0;
            }
        li{
            display: flex;
            flex-wrap: nowrap;
            font-size: 18px;
            line-height: 20px;
            font-weight: 600;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            width: 147px;
            // margin: 0px 80px;
            ::v-deep a{
                color: #fff;
                white-space:nowrap; 
            }
            ::v-deep a:hover, a:focus{
                color: #fff;
            }
        }
    }
}

.screen{
  position: relative;
  padding-bottom: 40px;
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 12px;
    position: absolute;
    left: 0;bottom: 0;
  }
}
.new-popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0);
    z-index: 999;
}

@media screen and (width:1200px) {
    .hearder_right{
        right: 15px !important;
    }
    .hearder_translate{
        left: 15px !important;
    }
}
@media screen and (max-width: 768px) {
  .icon_img{
    label{
      img{
        width: 24px;
      }
    }
    .mr50{
      margin-right: 40px;
    }
  }

  .m_menu_right{
    display: flex;
    .d_m_block{
      margin-left: 0px;
    }
  }
  .header{
    padding-top: 0;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;left:0;
    .hearder_list{
      display: none;
    }
    .m_header{
      padding: 7px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mobile_logo img{
        width: 100%;
        
      }
      .mobile_logo{
        align-items: center;
        height: 33px;
        width:33px;
      }
      .icon-gengduo{
        font-size: 20px;
        color: #fff;
      }
    }
    ul{
      width: 110px;
      padding: 10px 0;
      background: rgba(0, 0, 0, .8);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      margin-top: 0;
      display: block!important;
      position: absolute;
      top: 50px;
      right: 15px;
      z-index: 99999;
      li{
        width: auto;
        font-size: 14px;
        padding: 6px 0;
      }

      &:after{
        display: none;
      }
    }
  }
  .d_m_block{
    display: flex!important;
  }
  .d_m_none{
    display: none!important;
  }
}

@media screen and (min-width: 769px){
  .d_m_block{
    display: none!important;
  }
  .d_m_none{
    display: flex!important;
  }
}
</style>